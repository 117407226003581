
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$primary-color: #662D91;
$primary-dark: #441e61;
$secondary-color: #F4E6D7;
$accent-color: #FBB03B;
$background-image: url(../img/background/background-img.jpg);
$font-family: Baskerville, 'Baskerville Old Face', 'Hoefler Text', Garamond, 'Times New Roman', serif;
$dark-gray: rgb(32,32,32);

body{
  margin:0;
  padding:0;
  background-image: $background-image;
  font-family: $font-family;
}

/* UTILITY */
.centered { text-align:center; }
.clear{ clear:both; }
hr{ border-top: 1px solid $accent-color; }
p, ul, li{ font-size:1.1em; }
.dark-purple{ color: $primary-dark; }
/***********/



.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.tri-braid {
  width:100%;
  height:100px;
  background-image: url("../img/glyphs/tribraid-repeating.png");
  background-size: auto 20px;
  height: 20px;
  margin-bottom: 2px;
}

.container-fluid{
  padding:15px;

  .top-banner{
    height:160px;

    .site-logo{
      height:inherit;

      h1{
        height:100%;
        img{
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          width: 100%;
          height:auto;
        }
        margin:0;
      }
    }

    .nav-dropdown{
      height:inherit;
      display:block;
    }
  }

  .content-container{
    padding:15px;
    margin:0px;

    // MAIN CONTENT
    .content{
      background-color:$secondary-color;
      border:2px solid $accent-color;
      z-index:1;
      padding:15px;
      h2{
        background-color:$primary-color;
        color:white;
        text-align:center;
        padding:15px;
        margin: 0px -15px;
      }
      h3, h4{
        text-decoration:underline;
        color:$primary-dark;
      }
      img{
        width:100%;
        height:auto;
        padding:0;
        margin:15px 0px;
        border:1px solid $accent-color;
      }
      ul{

      }
    }
  }
}

.footer{
  height:150px;
  padding:15px;
  color:white;
  background-color:$dark-gray;
  .footer-logo{
    img{
      width:100%;
    }
  }
  .footer-copy{
    margin-top:15px;
    margin-bottom:15px;
  }
  .footer-nav{
    margin-top:15px;
    padding:0px;
    li{
      display:inline-block;
      padding-right:15px;
    }
  }
}

.purple-content{
  color:white;
  background-color:$primary-dark;
  padding:15px;
  text-align:center;
  border:1px solid $accent-color;
}

.contact-row{
  text-align:center;
  background-color:$primary-dark;
  margin:15px -15px -15px -15px;
  padding:15px;
  border-top:1px solid $accent-color;
  p{
    font-size:1.4em;
    a{
      color:rgb(255, 201, 128);
      text-decoration:underline;
      &:hover{
        color:$accent-color;
      }
    }
  }
  h3{
    color:white !important;
    margin:15px 0px;
  }
}

//For mobile
@media screen and (max-width: 768px) {
  .navigation{
    width:100%;
    background-color:rgba(0,0,0,0.6);
    box-sizing:border-box;
    z-index:1000;
    padding:0;margin:0;
    display:none;
    ul{
      width:inherit;
      list-style:none;
      padding:0;
      margin:0;
      li{
        width:inherit;
        padding:15px;
        a{
          color:white;
          display:block;
          width:inherit;
          padding:inherit;
        }
      }
    }
  }
  .content{
    ul{
      padding-left:15px;
    }
  }
}

//For desktop
@media screen and (min-width: 768px) {
  .top-banner{
    .site-logo{
      img{
        height:100% !important;
        width:auto !important;
      }
    }
    margin-bottom:15px;
  }
  .navigation{
    width:100%;
    z-index:2;
    padding:0px;
    ul{
      width:inherit;
      background-color:rgba(0,0,0,0.6);
      list-style:none;
      padding:0;
      margin:0;
      li{
        padding: 0px 15px;
        display:inline-block;
        a{
          color:white;
          display:block;
        }
      }
    }
  }
}
